import { createContext, ReactNode, useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { usePaymentTokenQuery } from 'generatedHooks/accounts/generated';
import { linkTypes } from 'apollo/link';
import { ModalTypes, Undefinable } from 'core/globalTypes';
import {
  DynamicNavBarType,
  useGetDynamicNavBarQuery
} from 'generatedHooks/commerce/generated';
import { useQuery } from 'hooks';
import qs from 'qs';
import noop from 'lodash/noop';
import { useLocation } from 'react-router-dom';

import { useProjectContext } from './ProjectProvider';

interface Props {
  children: ReactNode;
}

type GlobalPropsType = {
  setIsStripeFormOpened: Function;
  isStripeFormOpened: boolean;
  isPlanModalOpened: boolean;
  isPaymentMethodsModalOpened: boolean;
  setIsPlanModalOpened: Function;
  setIsPaymentMethodsModalOpened: Function;
  stripePromise: Promise<Stripe | null> | null;
  refetchPaymentToken: Function;
  clientSecret: Undefinable<string>;
  refetchEntities: boolean;
  setRefetchEntities: Function;
  dynamicNavbar: DynamicNavBarType;
  userId: Undefinable<string>;
  removeQueryParamForModal: Function;
  setPaymentMethodsActionCallback: Function;
  paymentMethodsActionCallback: Function;
};

export const GlobalPropsContext = createContext<GlobalPropsType>({
  setIsStripeFormOpened: () => {},
  isStripeFormOpened: false,
  isPlanModalOpened: false,
  isPaymentMethodsModalOpened: false,
  setIsPlanModalOpened: () => {},
  setIsPaymentMethodsModalOpened: () => {},
  setPaymentMethodsActionCallback: () => {},
  paymentMethodsActionCallback: noop,
  stripePromise: null,
  refetchPaymentToken: () => {},
  clientSecret: undefined,
  refetchEntities: false,
  setRefetchEntities: () => {},
  dynamicNavbar: DynamicNavBarType.Product,
  userId: undefined,
  removeQueryParamForModal: () => {}
});

const GlobalPropsProvider = ({ children }: Props) => {
  const { queryObject } = useQuery();

  const { pathname } = useLocation();

  const [isStripeFormOpened, setIsStripeFormOpened] = useState(false);
  const [isPlanModalOpened, setIsPlanModalOpened] = useState(false);
  const [isPaymentMethodsModalOpened, setIsPaymentMethodsModalOpened] =
    useState(false);

  const [paymentMethodsActionCallback, setPaymentMethodsActionCallback] =
    useState<Function>(noop);

  const removeQueryParamForModal = () => {
    delete queryObject.modal;
    const newQuery = qs.stringify(queryObject, { addQueryPrefix: true });
    const url = `${window.location.pathname}${newQuery}`;
    window.history.replaceState({}, '', url);
  };

  useEffect(() => {
    if (
      queryObject?.modal === ModalTypes.CHANGE_PLAN ||
      pathname.endsWith('/change-plan')
    ) {
      setIsPlanModalOpened(true);
    } else if (
      queryObject?.modal === ModalTypes.PAYMENT_METHODS ||
      pathname.endsWith('/payment-methods')
    ) {
      setIsPaymentMethodsModalOpened(true);
    }
  }, [queryObject?.modal, pathname]);

  const [refetchEntities, setRefetchEntities] = useState(false);

  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  const projectId = useProjectContext()?.id;

  const { data, refetch } = usePaymentTokenQuery({
    context: {
      urlType: linkTypes.accounts
    },
    variables: {
      projectId: projectId as string
    },
    fetchPolicy: 'network-only'
  });

  const { data: dynamicNavbarQueryData } = useGetDynamicNavBarQuery();

  useEffect(() => {
    if (data?.paymentToken?.paymentToken?.api_key) {
      const stripePromise = loadStripe(
        data.paymentToken.paymentToken.api_key as string
      );

      setStripePromise(stripePromise);
    }
  }, [data]);

  useEffect(() => {
    if (refetchEntities) {
      setRefetchEntities(false);
    }
  }, [refetchEntities]);

  const clientSecret = data?.paymentToken?.paymentToken?.client_secret;

  const dynamicNavbar =
    dynamicNavbarQueryData?.getDynamicNavBar || DynamicNavBarType.Product;

  const userId = data?.paymentToken.userId;

  return (
    <GlobalPropsContext.Provider
      value={{
        setIsStripeFormOpened,
        isStripeFormOpened,
        isPlanModalOpened,
        setIsPlanModalOpened,
        stripePromise,
        refetchPaymentToken: refetch,
        clientSecret,
        isPaymentMethodsModalOpened,
        setIsPaymentMethodsModalOpened,
        refetchEntities,
        setRefetchEntities,
        dynamicNavbar,
        userId,
        removeQueryParamForModal,
        paymentMethodsActionCallback,
        setPaymentMethodsActionCallback
      }}
    >
      {children}
    </GlobalPropsContext.Provider>
  );
};

export default GlobalPropsProvider;
