import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';
import { KeyValuePair } from 'core/globalTypes';

import { HOORY_EMBED } from '../core/constants/common';

const { VITE_APP_HOORY_WEBSITE_TOKEN, VITE_APP_HOORY_WEBSITE_TOKEN_FREE } =
  import.meta.env;

interface Props {
  children: ReactNode;
}

export const HooryContext = createContext<KeyValuePair>({
  hoory: {}
});

const HooryProvider = ({ children }: Props) => {
  const [isReady, setIsReady] = useState(false);

  const hooryReadyListener = useCallback(() => {
    window?.$hoory?.toggleBubbleVisibility('hide');
    setIsReady(true);
  }, []);

  useEffect(() => {
    window.addEventListener('hoory:ready', hooryReadyListener);

    const hooryScript = document.createElement('script');
    hooryScript.innerHTML = HOORY_EMBED(
      VITE_APP_HOORY_WEBSITE_TOKEN_FREE,
      VITE_APP_HOORY_WEBSITE_TOKEN
    );
    hooryScript.setAttribute('id', 'hoory-script');
    document.body.appendChild(hooryScript);

    return () => {
      window.removeEventListener('hoory:ready', hooryReadyListener);
    };
  }, []);

  return (
    <HooryContext.Provider
      value={{
        hoory: {
          setUserForAiSupport: window?.$hoory2?.setUser,
          setUserForHumanSupport: window?.$hoory?.setUser,
          openChat: () => window?.$hoory2?.toggle('open'),
          openHumanSupport: () => window?.$hoory?.toggle('open'),
          isChatInitialized: isReady
        }
      }}
    >
      {children}
    </HooryContext.Provider>
  );
};

export default HooryProvider;
