import { Flex, Form } from 'antd';
import { NiladicVoid } from 'core/globalTypes';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetUserProjectNotificationSettingsDocument,
  useGetUserProjectNotificationSettingsLazyQuery,
  useUpdateUserProjectNotificationSettingsMutation
} from 'generatedHooks/accounts/generated';
import { linkTypes } from 'apollo/link';
import { SystemMessage } from 'helpers';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import { Switch, Typography, Modal } from 'components/basic';
import { DEFAULT_NOTIFICATION_SETTINGS, FORM_ITEMS } from './constants';
import { FormItemNames } from './types';
import ListSkeleton from 'components/shared/Skeleton/components/List';

const { UcBodyText } = Typography;
const { useForm } = Form;

interface Props {
  projectId: string;
  projectClientName: string;
  open: boolean;
  onClose: NiladicVoid;
}

const NotificationSettingsModal: FC<Props> = ({
  open,
  onClose,
  projectId,
  projectClientName
}) => {
  const { t } = useTranslation(['common', 'projects']);
  const [form] = useForm();

  const [getSettings, { loading: notificationSettingsLoading }] =
    useGetUserProjectNotificationSettingsLazyQuery({
      context: {
        urlType: linkTypes.accounts
      },
      onCompleted(data) {
        const { receiveMailNotification, receivePushNotification } =
          data.getUserProjectNotificationSettings ||
          DEFAULT_NOTIFICATION_SETTINGS;

        form.setFieldsValue({
          [FormItemNames.ReceiveMailNotification]: receiveMailNotification,
          [FormItemNames.ReceivePushNotification]: receivePushNotification
        });
      }
    });

  const [
    updateNotificationSettings,
    { loading: updateNotificationSettingsLoading }
  ] = useUpdateUserProjectNotificationSettingsMutation({
    context: {
      urlType: linkTypes.accounts,
      hideMessages: true
    }
  });

  const onUpdateNotificationSettings = async () => {
    try {
      const values = form.getFieldsValue();

      await updateNotificationSettings({
        variables: {
          input: {
            projectId,
            receiveMailNotification:
              values[FormItemNames.ReceiveMailNotification],
            receivePushNotification:
              values[FormItemNames.ReceivePushNotification]
          }
        },
        onCompleted() {
          SystemMessage.success(
            t(
              'projects:updateNotificationSettingsSuccess',
              'Notification settings have been updated'
            )
          );
        },
        onError() {
          SystemMessage.error('projects:updateNotificationSettingsError');
        },
        update(cache, { data }) {
          cache.updateQuery(
            {
              query: GetUserProjectNotificationSettingsDocument,
              variables: { input: { projectId } }
            },
            () => {
              return {
                getUserProjectNotificationSettings: {
                  ...data?.updateUserProjectNotificationSettings
                }
              };
            }
          );
        }
      });

      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (open && projectId) {
      getSettings({
        variables: {
          input: {
            projectId
          }
        }
      });
    }
  }, [open, projectId]);

  return (
    <Modal
      centered
      open={open}
      onCancel={onClose}
      cancelText={t('common:cancel')}
      okText={t('common:save')}
      onOk={onUpdateNotificationSettings}
      confirmLoading={updateNotificationSettingsLoading}
      title={t('projects:notificationSettings')}
      bodyStyle={{ maxHeight: 700, overflow: 'auto' }}
    >
      <Flex vertical gap={48}>
        <Flex vertical gap={12}>
          <UcBodyText size="base" fontWeight="bold">
            {projectClientName}
          </UcBodyText>
          <UcBodyText type="secondary-dark" size="base" fontWeight="medium">
            {t('projects:notificationSettingsModalText')}
          </UcBodyText>
        </Flex>
        <ListSkeleton loading={notificationSettingsLoading} count={2}>
          <Form form={form} requiredMark={false}>
            <Flex vertical gap={24}>
              {FORM_ITEMS.map(({ labelKey, name }) => {
                return (
                  <Flex key={name} align="center" justify="space-between">
                    <UcBodyText size="small" type="secondary-dark">
                      {t(labelKey)}
                    </UcBodyText>
                    <Form.Item name={name} noStyle>
                      <Switch
                        data-test={createDataTestAttribute({
                          dataTestAttribute: DataTestAttributes.Switcher,
                          prefix: name
                        })}
                        size="small"
                      />
                    </Form.Item>
                  </Flex>
                );
              })}
            </Flex>
          </Form>
        </ListSkeleton>
      </Flex>
    </Modal>
  );
};

export default NotificationSettingsModal;
